globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"70fdfdd97c52d620d2e9a293bd044035dbff5ce3"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { init, browserTracingIntegration } from '@sentry/nextjs';

const isProd = process.env.BUILD_ENV === 'production';
const isSentryEnabled = process.env.SENTRY_ENABLED === 'true';

if (isSentryEnabled) {
    init({
        dsn: process.env.SENTRY_DSN,

        environment: process.env.BUILD_ENV,
        tracesSampleRate: 1, //enable 1 for each environment, will be adjusted later

        // Setting this option to true will print useful information to the console while you're setting up Sentry.
        debug: false,

        replaysOnErrorSampleRate: 1.0, //enable 1 for each environment, will be adjusted later

        replaysSessionSampleRate: 1.0, //enable 1 for each environment, will be adjusted later

        integrations: [browserTracingIntegration()],
    });
}

import('./sentry.replay').then(({ initReplay }) => initReplay());
